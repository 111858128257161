import { Image, Link, type LinkProps } from "@chakra-ui/react";
import NextLink from "next/link";
import { route } from "nextjs-routes";
import { memo } from "react";

type Props = {
  onClick?: LinkProps["onClick"];
} & LinkProps;

const HeaderLogo = ({ onClick, ...props }: Props) => {
  return (
    <Link
      as={NextLink}
      href={route({ pathname: "/" })}
      prefetch
      scroll
      display="inline-flex"
      overflow="hidden"
      flexShrink={0}
      aria-label="Link to home"
      alignItems="center"
      gap={2}
      onClick={onClick}
      {...props}
    >
      <Image
        src="/icons/logo/logo.svg"
        alt="Seitrace logo"
        boxSize={{ base: 8, lg: 7, xl: 8 }}
        flexShrink={0}
      ></Image>
      <Image
        alt="Seitrace"
        src="/icons/logo/seitrace.svg"
        flexShrink={0}
        color="neutral.light.8"
        width={{ base: "7.59688rem", lg: "6.64725rem", xl: "7.59688rem" }}
        height={{ base: "1.08rem", lg: "0.945rem", xl: "1.08rem" }}
      ></Image>
    </Link>
  );
};

export default memo(HeaderLogo, (prev, next) => {
  return prev.onClick === next.onClick;
});
