import { Box, useToken, type BoxProps } from "@chakra-ui/react";
import { memo } from "react";

// const ICONS: Record<string, IconName> = {
//   "404": "error-pages/404",
//   "403": "error-pages/403",
//   "422": "error-pages/422",
//   "429": "error-pages/429",
//   "500": "error-pages/500",
// };

type Props = {
  status: number | undefined;
} & BoxProps;

const AppErrorIcon = ({ status, ...props }: Props) => {
  const neutralLight3 = useToken("colors", "neutral.light.3");

  return (
    <Box
      width="full"
      paddingX={4}
      userSelect="none"
      fontSize="clamp(200px, 25vw, 750px)"
      fontWeight="bold"
      textAlign="center"
      color="neutral.light.2"
      sx={{
        WebkitTextStroke: "clamp(2px, 0.15vw, 0.5rem)",
        WebkitTextStrokeColor: neutralLight3,
      }}
      {...props}
    >
      {status ?? 500}
    </Box>
  );
};

export default memo(AppErrorIcon, (prev, next) => prev.status === next.status);
